<template>
  <div style="padding-left: 33px">
    <p>I am codeeer, a coder with more <span style="color:green">e</span>nergy, <span style="color:green">e</span>xcellence and <span style="color:green">e</span>xploration!</p>
    <div class="line"></div>
    <p>I believe in simple is better.</p>
    <p>I made some products <a target="_blank" href="https://v.watch/">VWatch</a>, <a target="_blank" href="https://ai.st.link">AIAgent</a>, and some tools <a target="_blank" href="https://dao.st.link/">梅花易数</a>, <a target="_blank" href="https://avatar.ok.sb/">Avatar</a>.</p>
    <p>Contact me with <a target="_blank" href="mailto:greatcodeeer@gmail.com">Email</a>, <a target="_blank" href="https://github.com/greatcodeeer">Github</a>.</p>
    <p>🧑‍💻 I am the ONE.</p>
  </div>
</template>


<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

p {
  text-align: left;
}

.line {
  border-top: 1px solid #eee;
  height: 1px;
  width: 66%;
}

a:link,
a:visited {
  color: black;
}

a:hover,
a:active {
  color: green;
}
</style>
